<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-card>
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <!-- <div>
          <ejs-dropdownlist
            id="dropdownlist"
            ref="dropdown"
            :data-source="ddData"
            width="220px"
            :fields="fields"
            :change="valueChange"
            :value="ddValue"
          />
          <span
            id="msg"
            ref="msgelement"
            v-html="loadTime"
          />
          <br>
        </div> -->
        <ejs-grid
          id="overviewgrid"
          ref="overviewgrid"
          :data-source="actionHistory"
          :selection-settings="selectionSettings"
          :edit-settings="editSettings"
          :allow-filtering="true"
          :filter-settings="filterOptions"
          :show-column-chooser="true"
          :allow-selection="true"
          :allow-sorting="true"
          :allow-resizing="true"
          :enable-virtualization="false"
          :allow-row-drag-and-drop="false"
          height="600"
          :pdf-header-query-cell-info="pdfHeaderQueryCellInfo"
          :allow-excel-export="true"
          :allow-pdf-export="true"
          row-height="38"
          :enable-hover="false"
          :enable-header-focus="true"
          :load="load"
          :toolbar="toolbar"
          :toolbar-click="toolbarClick"
          :action-begin="actionTableBegin"
          :action-complete="actionTableComplete"
          :allow-grouping="true"
          :allow-paging="true"
          :page-settings="pageSettings"
          :data-bound="dataBound"
          :command-click="commandColumm"
        >
          <e-columns>
            <e-column
              type="checkbox"
              :allow-filtering="false"
              :allow-sorting="false"
              width="60"
            />
            <e-column
              field="created_at"
              :visible="false"
              header-text="Data Creazione"
              :is-primary-key="true"
              width="130"
            />
            <e-column
              field="event"
              header-text="Evento"
              width="200"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="email"
              header-text="Email"
              width="170"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="new_values"
              header-text="Valore Inserito"
              width="800"
              :filter="filter"
            />
            <!-- <e-column
              field="Location"
              header-text="Location"
              width="140"
              :filter="filter"
              :template="coltemplate"
            />
            <e-column
              field="Status"
              header-text="Status"
              width="150"
              :filter="filter"
              :template="statusTemplate"
            />
            <e-column
              field="Trustworthiness"
              header-text="Trustworthiness"
              width="200"
              :filter="filter"
              :template="trustTemplate"
            />
            <e-column
              field="Rating"
              header-text="Rating"
              width="160"
              :template="ratingTemplate"
              :allow-filtering="false"
            />
            <e-column
              field="Software"
              :allow-filtering="false"
              :allow-sorting="false"
              header-text="Software Proficiency"
              width="60"
              :template="progessTemplate"
            />
            <e-column
              field="CurrentSalary"
              header-text="Current Salary"
              format="C2"
              width="160"
              text-align="Right"
            />
            <e-column
              field="Address"
              header-text="Address"
              width="240"
              clip-mode="EllipsisWithTooltip"
            /> -->
          </e-columns>
        </ejs-grid>
      </div>
    </div>
    <SettingsPdfExport
      :show="showModalSettingPdfExport"
      @pdf-export-confirm-close="closeModal"
    />
  </b-card>
</template>
<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Page, ColumnChooser, Group, CommandColumn, Resize, Scroll, Aggregate,
} from '@syncfusion/ej2-vue-grids'
// import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns'
import { BCard } from 'bootstrap-vue'
import SettingsPdfExport from '@/components/pdfExportModal/SettingsPdfExport.vue'

Vue.use(GridPlugin)
//Vue.use(DropDownListPlugin)

export default Vue.extend({
  components: {
    BCard,
    SettingsPdfExport,
  },
  props: {
    actionHistory: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
          cTemplate: function () {
          return { template : Vue.component('columnTemplate',{
             template: `<div class="image">
                    <img :src="image" :alt="altImage"/>
                </div>`,
                data: function() {
                    return {
                        data: {}
                    }
                },
                computed: {
                    image: function() {
                        return './' + this.data.EmployeeID + '.png'
                    },
                    altImage: function() {
                        return this.data.EmployeeID
                    }
                }
          })
          }
          },
    pageSettings: { pageSize: 25, pageSizes: [25,50,100,200], pageCount: 5 },
    showModalSettingPdfExport: false,
    editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' },
    toolbar: ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Print'],
    toolbarOptions: ['ExcelExport'],
    ddData: [{ value: 1000, text: '1,000 Rows and 11 Columns' }, { value: 10000, text: '10,000 Rows and 11 Columns' }],
    ddValue: 10000,
    stTime: null,
    loadTime: null,
    dReady: false,
    isDataChanged: true,
    fields: { text: 'text', value: 'value' },
    empTemplate() {
      return {
        template: Vue.component('empTemplate', {
          data() { return { data: {}, usermale: 'usermale', userfemale: 'userfemale' } },
          template: `<div>
                            <div class="e-grid-empimg">
                                <span :class="{'e-userimg': true, 'sf-grid-icon-Male': data.EmployeeImg == usermale, 'sf-grid-icon-FeMale': data.EmployeeImg == userfemale}"></span>
                                </span>
                            </div>
                            <span id="Emptext">{{data.Employees}}</span>
                        </div>`,
        }),
      }
    },
    coltemplate() {
      return {
        template: Vue.component('coltemplate', {
          data() { return { data: {} } },
          template: '<div><img src="src/grid/images/Map.png" class="e-image" :alt="data.Location"/> <span id="locationtext">{{data.Location}}</span></div>',
        }),
      }
    },

    statusTemplate() {
      return {
        template: Vue.component('statusTemplate', {
          data() { return { data: {}, active: 'Active', inactive: 'Inactive' } },
          template: '<div id="status" :class="{\'statustemp\': true, \'e-activecolor\': data.Status == active, \'e-inactivecolor\': data.Status == inactive}">\
             <span :class="{\'statustxt\': true, \'e-activecolor\': data.Status == active, \'e-inactivecolor\': data.Status == inactive}">{{data.Status}}</span></div>',
        }),
      }
    },
    trustTemplate() {
      return {
        template: Vue.component('trustTemplate', {
          data() {
            return {
              data: {}, Perfect: 'Perfect', Sufficient: 'Sufficient', Insufficient: 'Insufficient',
            }
          },
          computed: {
            image() {
              return `src/grid/images/${this.data.Trustworthiness}.png`
            },
          },
          template: '<div><img style="width: 31px; height: 24px" :src="image" :alt="data.Trustworthiness" /><span id="Trusttext">{{data.Trustworthiness}}</span></div>',
        }),
      }
    },
    ratingTemplate() {
      return {
        template: Vue.component('ratingTemplate', {
          data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
          template: '<div id="status" class="rating">\
                        <span v-for="i in item" :class="{checked: i <= data.Rating, star: true}"></span>\
                      </div>',
        }),
      }
    },

    progessTemplate() {
      return {
        template: Vue.component('progessTemplate', {
          data() { return { data: {}, inactive: 'Inactive' } },
          computed: {
            width() {
              if (this.data.Software <= 20) {
                this.data.Software += 20
              }
              return `${this.data.Software}%`
            },
          },
          template: `<div id="myProgress" class="pbar">
                          <div id="myBar" :class="{'bar': true, 'progressdisable': data.Status == inactive}" :style="{'width': width}">
                            <div id="label" class="barlabel" v-html=width ></div>
                          </div>
                        </div>`,
        }),
      }
    },
    filterOptions: {
      type: 'Menu',
    },
    filter: {
      type: 'CheckBox',
    },
    selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
  }),
  computed: {
    getTradeData() {
      let dataCount = this.ddValue
      const employees = [
        'Michael', 'Kathryn', 'Tamer', 'Martin', 'Davolio', 'Nancy', 'Fuller', 'Leverling', 'Peacock',
        'Margaret', 'Buchanan', 'Janet', 'Andrew', 'Callahan', 'Laura', 'Dodsworth', 'Anne',
        'Bergs', 'Vinet', 'Anton', 'Fleet', 'Zachery', 'Van', 'King', 'Jack', 'Rose']
      const designation = ['Manager', 'CFO', 'Designer', 'Developer', 'Program Directory', 'System Analyst', 'Project Lead']
      const mail = ['sample.com', 'arpy.com', 'rpy.com', 'mail.com', 'jourrapide.com']
      const location = ['UK', 'USA', 'Sweden', 'France', 'Canada', 'Argentina', 'Austria', 'Germany', 'Mexico']
      const status = ['Active', 'Inactive']
      const trustworthiness = ['Perfect', 'Sufficient', 'Insufficient']
      const tradeData = []
      const address = ['59 rue de lAbbaye', 'Luisenstr. 48', 'Rua do Paço, 67', '2, rue du Commerce', 'Boulevard Tirou, 255',
        'Rua do mailPaço, 67', 'Hauptstr. 31', 'Starenweg 5', 'Rua do Mercado, 12',
        'Carrera 22 con Ave. Carlos Soublette #8-35', 'Kirchgasse 6',
        'Sierras de Granada 9993', 'Mehrheimerstr. 369', 'Rua da Panificadora, 12', '2817 Milton Dr.', 'Kirchgasse 6',
        'Åkergatan 24', '24, place Kléber', 'Torikatu 38', 'Berliner Platz 43', '5ª Ave. Los Palos Grandes', '1029 - 12th Ave. S.',
        'Torikatu 38', 'P.O. Box 555', '2817 Milton Dr.', 'Taucherstraße 10', '59 rue de lAbbaye', 'Via Ludovico il Moro 22',
        'Avda. Azteca 123', 'Heerstr. 22', 'Berguvsvägen  8', 'Magazinweg 7', 'Berguvsvägen  8', 'Gran Vía, 1', 'Gran Vía, 1',
        'Carrera 52 con Ave. Bolívar #65-98 Llano Largo', 'Magazinweg 7', 'Taucherstraße 10', 'Taucherstraße 10',
        'Av. Copacabana, 267', 'Strada Provinciale 124', 'Fauntleroy Circus', 'Av. dos Lusíadas, 23',
        'Rua da Panificadora, 12', 'Av. Inês de Castro, 414', 'Avda. Azteca 123', '2817 Milton Dr.']
      const employeeimg = ['usermale', 'userfemale']
      if (typeof dataCount === 'string') {
        dataCount = parseInt(dataCount, 10)
      }
      for (let i = 1; i <= dataCount; i++) {
        const code = 10000
        tradeData.push({
          EmployeeID: code + i,
          Employees:
                        `${employees[Math.floor(Math.random() * employees.length)]} ${employees[Math.floor(Math.random() * employees.length)]}`,
          Designation: designation[Math.floor(Math.random() * designation.length)],
          Location: location[Math.floor(Math.random() * location.length)],
          Status: status[Math.floor(Math.random() * status.length)],
          Trustworthiness: trustworthiness[Math.floor(Math.random() * trustworthiness.length)],
          Rating: Math.floor(Math.random() * 5),
          Software: Math.floor(Math.random() * 100),
          EmployeeImg: employeeimg[Math.floor(Math.random() * employeeimg.length)],
          CurrentSalary: Math.floor((Math.random() * 100000)),
          Address: address[Math.floor(Math.random() * address.length)],
        })
        const employee = 'Employees'
        const emp = tradeData[i - 1][employee]
        const sName = emp.substr(0, emp.indexOf(' ')).toLowerCase()
        const empmail = 'Mail'
        tradeData[i - 1][empmail] = `${sName + (Math.floor(Math.random() * 100) + 10)}@${mail[Math.floor(Math.random() * mail.length)]}`
      }
      this.stTime = performance.now()
      // console.log(tradeData)
      return tradeData
    },
  },
  methods: {
    dataBound: function() {
      // console.log(this.$refs.overviewgrid)
    },
    commandColumm(args) {
      if(args.commandColumn.type === 'Dettagli') {
            alert('ciao')
        }
    },
    contextMenuClick(args) {
        // console.log('contextMenuClick', args)
        if(args.item.id === 'Dettagli') {
            alert('ciao')
        }
    },
    closeModal() {
      this.showModalSettingPdfExport = false
    },
    actionTableBegin() {
      // console.log(action)
    },
    actionTableComplete() {
      // console.log(row)
    },
    pdfHeaderQueryCellInfo() {
      // args.cell.row.pdfGrid.repeatHeader = true;
    },
     toolbarClick(args) {
        // console.log(args)
        if (args.item.id === 'Dettagli') {
            alert("Custom Toolbar Click...");
        }
        if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
          this.showModalSettingPdfExport = true
            const exportProperties = {
                //you can customize the name as per your requirement
                fileName: 'prova.pdf',
             }
            this.$bvModal.show('setting-pdf-export-modal')
            this.$refs.overviewgrid.pdfExport(exportProperties)
            // this.$refs.overviewgrid.pdfExport(exportProperties)
        }
        if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
            this.$refs.overviewgrid.excelExport()
        }
        if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
            this.$refs.overviewgrid.csvExport()
        }
     },
    valueChange(args) {
      this.$refs.dropdown.$el.ej2_instances[0].closePopup()
      this.$refs.overviewgrid.showSpinner()
      this.isDataChanged = true
      this.stTime = null
      const contentElement = this.$refs.overviewgrid.ej2Instances.contentModule.content
      this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage = 1
      contentElement.scrollLeft = 0
      contentElement.scrollTop = 0
      // console.log(this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage)
      this.stTime = performance.now()
      this.ddValue = args.value
      this.$refs.overviewgrid.hideSpinner()
    },
    load() {
      const proxy = this
      this.$refs.overviewgrid.$el.ej2_instances[0].on('data-ready', () => {
        proxy.dReady = true
      })
      this.$refs.overviewgrid.$el.addEventListener('DOMSubtreeModified', () => {
        if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
          const e = performance.now() - proxy.stTime
          proxy.loadTime = `Load Time: <b>${e.toFixed(0)}</b><b>ms</b>`
          proxy.stTime = null
          proxy.dReady = false
          proxy.isDataChanged = false
          // proxy.$refs.msgelement.classList.remove('e-hide')
        }
      })
    },
  },
  provide: {
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Page, ColumnChooser, Group, Resize, Scroll, Aggregate, CommandColumn],
  },

})

</script>
<style scoped>
/* @import "../../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css"; */
</style>
