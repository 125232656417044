<template>
  <div>
    <b-card>
      <b-row
        class="mb-2"
      >
        <h4>Profilo da modificare</h4>
        <b-col
          cols="4"
        >
          <!-- <b-form-select
            v-model="selected"
            :options="permissions"
          /> -->
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :clearable="false"
            placeholder="Seleziona ruolo/i"
            :options="permissions"
          />
        </b-col>
        <b-col>
          <b-button
            v-b-modal="'addPermission'"
            variant="primary"
          >
            Aggiungo Profilo
          </b-button>
          <add-permission @push-profile="pushProfile" />
        </b-col>
      </b-row>
      <b-table
        v-if="items2.length > 0"
        :items="items2"
        :fields="fields"
        striped
        responsive
      >
        <!-- <template #row-details="row">
          <b-card>
            <b-row
              class="mb-3 d-flex justify-content-flex-end"
            >
              <b-form-radio
                v-model="leggendRadio[0]"
                name="leggendaNotVisible"
                :value="0"
                class="custom-control-primary ml-1"
              >Non Visibile</b-form-radio>
              <b-form-radio
                v-model="leggendRadio[1]"
                name="leggendaVisible"
                :value="1"
                class="custom-control-secondary ml-1"
              >Visibile</b-form-radio>
              <b-form-radio
                v-model="leggendRadio[2]"
                name="leggendaWrite"
                :value="2"
                class="custom-control-success ml-1"
              >Write</b-form-radio>
            </b-row>
            <b-row class="mb-2">
              <b-col
                cols="12"
              >
                <b-row>
                  <b-col
                    v-for="(field, index) in row.item.fields"
                    :key="index"
                    cols="4"
                    md="3"
                    lg="2"
                    class="mb-1 d-flex justify-content-around"
                  >
                    <strong class="">{{ field.name }}: </strong>
                    <div class="d-flex">
                      <b-form-radio
                        v-model="field.value"
                        :name="field.id"
                        :value="0"
                        class="custom-control-primary"
                      />
                      <b-form-radio
                        v-model="field.value"
                        :name="field.id"
                        :value="1"
                        class="custom-control-secondary"
                      />
                      <b-form-radio
                        v-model="field.value"
                        :name="field.id"
                        :value="2"
                        class="custom-control-success"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-button
              size="sm"
              variant="outline-success"
              class="mr-4"
              @click="print(row.item)"
            >
              Salva
            </b-button>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Nascondi
            </b-button>
          </b-card>
        </template> -->

        <template #cell(elemento)="data">
          {{ data.item.title }}
        </template>

        <template #cell(visualizza)="data">
          <b-form-checkbox
            v-model="data.item.crudp.visualizza"
            plain
            value="1"
            unchecked-value="0"
            class="vs-checkbox-con"
          />
        </template>
        <template #cell(crea)="data">
          <b-form-checkbox
            v-model="data.item.crudp.crea"
            plain
            value="1"
            unchecked-value="0"
            class="vs-checkbox-con"
          />
        </template>

        <template #cell(leggi)="data">
          <b-form-checkbox
            v-model="data.item.crudp.leggi"
            plain
            value="1"
            unchecked-value="0"
            class="vs-checkbox-con"
          />
        </template>

        <template #cell(aggiorna)="data">
          <b-form-checkbox
            v-model="data.item.crudp.aggiorna"
            plain
            value="1"
            unchecked-value="0"
            class="vs-checkbox-con"
          />
        </template>

        <template #cell(elimina)="data">
          <b-form-checkbox
            v-model="data.item.crudp.elimina"
            plain
            value="1"
            unchecked-value="0"
            class="vs-checkbox-con"
          />
        </template>

        <template #cell(stampa)="data">
          <b-form-checkbox
            v-model="data.item.crudp.stampa"
            plain
            value="1"
            unchecked-value="0"
            class="vs-checkbox-con"
          />
        </template>

        <template #cell(Azioni)="data">
          <!-- <b-button
            v-model="row.detailsShowing"
            variant="outline-primary"
            size="sm"
            @click="row.toggleDetails"
          >
            <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button> -->
          <b-button
            variant="outline-primary"
            size="sm"
            @click="postProfilo(data)"
          >
            Salva
          </b-button>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'
import AddPermission from '@/views/pages/settings/generalSettings/components/AddPermission.vue'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    vSelect,
    AddPermission,
  },
  props: {
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // select
      options: [
        { value: null, text: 'Please select an option', disabled: true },
        { value: 'customers', text: 'Clienti' },
        { value: 'patients', text: 'Pazienti' },
        { value: 'suppliers', text: 'Fornitori' },
      ],
      selected: {},
      // table
      leggendRadio: [0, 1, 2],
      fields: ['elemento', 'visualizza', 'crea', 'leggi', 'aggiorna', 'elimina', 'stampa', 'Azioni'],
      items: [
        {
          id: 1,
          elemento: 'Pazienti',
          visualizza: true,
          crea: true,
          leggi: true,
          aggiorna: true,
          elimina: false,
        },
        {
          id: 2,
          elemento: 'Fornitori',
          visualizza: true,
          crea: true,
          leggi: true,
          aggiorna: true,
          elimina: false,
        },
        {
          id: 3,
          elemento: 'Clienti',
          visualizza: true,
          crea: true,
          leggi: true,
          aggiorna: true,
          elimina: false,
        },
      ],
      items2: [

      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  watch: {
    selected() {
      // console.log(newValue, old)
      this.getProfili()
    },
  },
  mounted() {
    this.getProfili()
  },
  methods: {
    print() {
      // console.log(row)
    },
    pushProfile(data) {
      // console.log('profile2', data)
      this.$emit('push-profile', data)
    },
    getProfili() {
      // console.log(this.selected.title)
      axios.post(process.env.VUE_APP_URL_API_SERVER, { ruolo: this.selected.title },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Acl',
            metodo: 'getProfiloL1',
            ACL: 'invoice-list',
          },
        }).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          this.items2 = res.data.profiloL1.map(el => (
            {
              id: el.id,
              title: el.titolo,
              crudp: {
                visualizza: el.crudp.substring(0, 1),
                crea: el.crudp.substring(1, 2),
                leggi: el.crudp.substring(2, 3),
                aggiorna: el.crudp.substring(3, 4),
                elimina: el.crudp.substring(4, 5),
                stampa: el.crudp.substring(5, 6),
              },
            }
          ))
          // console.log('dopo map', this.items2)
        }
      })
        .catch(e => e)
    },
    postProfilo(data) {
      // console.log(data.item.crudp)
      const profilo = {
        id_profiloL1: data.item.id,
        ruolo: this.selected.title,
        crudp: String(data.item.crudp.visualizza) + String(data.item.crudp.crea) + String(data.item.crudp.leggi) + String(data.item.crudp.aggiorna) + String(data.item.crudp.elimina) + String(data.item.crudp.stampa),

      }
      // console.log('profilo', profilo)
      axios.post(process.env.VUE_APP_URL_API_SERVER, profilo,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Acl',
            metodo: 'setProfiloL1',
            ACL: 'invoice-list',
          },
        }).then(res => {
        if (res.data.esito === 'OK') {
          this.alertSuccess()
        }
      })
        .catch(e => e)
    },
    alertSuccess() {
      this.$swal({
        title: 'Salvataggio Effettuato Correttamente',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style scoped>
.justify-content-flex-end {
  justify-content: flex-end;
}
</style>
