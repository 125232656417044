<template>
  <b-modal
    id="addPermission"
    ref="addPermission"
    centered
    :title="'Aggiungi Ruolo'"
    hide-footer
    no-close-on-backdrop
  >
    <b-row no-gutters>
      <b-col
        cols="12"
      >
        <b-form-group
          :label="'Nome Ruolo'"
          label-class="font-weight-bolder"
        >
          <b-form-input v-model="permission" />
          {{ permission }}
        </b-form-group>
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      @click="addPermission"
    >Salva</b-button>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormInput, BCol, BRow, BButton,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BButton,
  },
  data() {
    return {
      permission: '',
    }
  },
  methods: {
    async addPermission() {
      const permission = { ruolo: this.permission }
      // console.log('data addContact', data)
      await axios.post(process.env.VUE_APP_URL_API_SERVER, permission,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Acl',
            metodo: 'creaProfilo',
            ACL: 'invoice-list',
          },
        }).then(res => {
        // ritorna esito ==
        // console.log(res)
        if (res.data.esito) {
          this.$emit('push-profile', res.data.permissions)
          this.alertSuccess()
        }
      }).catch(e => e)
      this.$refs.addPermission.hide()
    },
    alertSuccess() {
      this.$swal({
        title: 'Salvataggio Effettuato Correttamente',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    // this.$refs.addaddress.hide()
    // e.preventDefault()
    // this.$refs.addaddress.hide()
    // this.$root.$on('bv::modal::hide', 'addaddress')
    // this.$refs.addaddress.hide()
  },
}
</script>

<style>

</style>
