import { render, staticRenderFns } from "./Profile2.vue?vue&type=template&id=181f78e4&scoped=true&"
import script from "./Profile2.vue?vue&type=script&lang=js&"
export * from "./Profile2.vue?vue&type=script&lang=js&"
import style0 from "./Profile2.vue?vue&type=style&index=0&id=181f78e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "181f78e4",
  null
  
)

export default component.exports