<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-card>
    <b-row no-gutters>
      <b-col
        class="d-flex justify-content-end"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-toggle.sidebar-add-new-event-my
          variant="outline-primary"
          class=""
          @click="prepareSidebarToEdit({}, 'Aggiungi Collaboratore')"
        >
          Aggiungi
        </b-button>
      </b-col>
    </b-row>
    <div class="col-lg-12 control-section mt-2 p-0">
      <div class="content-wrapper">
        <!-- <div>
          <ejs-dropdownlist
            id="dropdownlist"
            ref="dropdown"
            :data-source="ddData"
            width="220px"
            :fields="fields"
            :change="valueChange"
            :value="ddValue"
          />
          <span
            id="msg"
            ref="msgelement"
            v-html="loadTime"
          />
          <br>
        </div> -->
        <ejs-grid
          id="overviewgrid"
          ref="overviewgrid"
          locale="it-IT"
          :data-source="collaborators"
          :selection-settings="selectionSettings"
          :edit-settings="editSettings"
          :allow-filtering="true"
          :filter-settings="filterOptions"
          :show-column-chooser="true"
          :allow-selection="true"
          :allow-sorting="true"
          :allow-resizing="true"
          :enable-virtualization="false"
          :allow-row-drag-and-drop="true"
          height="600"
          :pdf-header-query-cell-info="pdfHeaderQueryCellInfo"
          :allow-excel-export="true"
          :allow-pdf-export="true"
          row-height="38"
          :enable-hover="false"
          :enable-header-focus="true"
          :load="load"
          :toolbar="toolbar"
          :toolbar-click="toolbarClick"
          :action-begin="actionTableBegin"
          :action-complete="actionTableComplete"
          :allow-grouping="false"
          :allow-paging="true"
          :page-settings="pageSettings"
          :context-menu-items="contextMenuItems"
          :context-menu-click="contextMenuClick"
          :data-bound="dataBound"
          :command-click="commandColumm"
        >
          <e-columns>
            <e-column
              type="checkbox"
              :allow-filtering="false"
              :allow-sorting="false"
              width="70"
            />
            <e-column
              field="id"
              :visible="false"
              header-text="ID"
              :is-primary-key="true"
              width="130"
            />
            <e-column
              field="nome"
              header-text="Nome"
              width="200"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
              :template="nameAvatar"
              :validation-rules="collaboratorNameRules"
            />
            <e-column
              field="cognome"
              header-text="Cognome"
              width="170"
              clip-mode="EllipsisWithTooltip"
              :validation-rules="collaboratorSurnameRules"
            />
            <e-column
              field="ruolo"
              header-text="Ruolo"
              width="250"
              :filter="filter"
              :template="roleTemplate"
              :allow-editing="false"
            /><!-- edit-type="dropdownedit"
              :edit="countryParams" -->
            <e-column
              field="email"
              header-text="Email Princiaple"
              width="140"
              :filter="filter"
            />
            <e-column
              field="cellulare"
              header-text="Telefono"
              width="150"
              :filter="filter"
            />
            <e-column
              field="codice_fiscale"
              header-text="Cod. Fiscale"
              width="170"
              :filter="filter"
            />
            <e-column
              field="partita_iva"
              header-text="P.IVA"
              width="160"
              :template="ratingTemplate"
              :allow-filtering="false"
            />
            <e-column
              field="stato"
              :allow-filtering="false"
              :allow-sorting="false"
              header-text="Stato"
              width="100"
              :template="statusTemplate"
            />
            <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="150"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
              :allow-filtering="false"
              hide-at-media="(min-width:900px)"
            />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
    <SettingsPdfExport
      :show="showModalSettingPdfExport"
      @pdf-export-confirm-close="closeModal"
    />
    <div>
      <collaborators-event-handler
        ref="sidebar-collaborators"
        :collaborator="collaborator"
        @remove-event="removeCollaborator"
        @add-new-collaborator="addToCollaborator"
        @update-collaborator="updateCollaborator"
      />
    </div>
  </b-card>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Aggregate,
} from '@syncfusion/ej2-vue-grids'
import {
  BButton, BCol, BRow, VBToggle, BCard, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { italian } from '@/@core/grid-translation/it-IT'
import { L10n, setCulture } from '@syncfusion/ej2-base';
// import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { Query } from '@syncfusion/ej2-data';
import SettingsPdfExport from '@/components/pdfExportModal/SettingsPdfExport.vue'
import CollaboratorsEventHandler from './collaboratorsEventHandler/CollaboratorsEventHandler.vue'
import vSelect from 'vue-select'

import axios from 'axios'

Vue.use(GridPlugin)

setCulture('it-IT');
L10n.load(italian)
let country= [
        { countryName: 'United States', countryId: '1' },
        { countryName: 'Australia', countryId: '2' }
    ];
export default Vue.extend({
  components: {
    BCard,
    BButton,
    BCol,
    BRow,
    SettingsPdfExport,
    CollaboratorsEventHandler,
    BBadge,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    collaborators: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
      countryParams: {
          params:   {
            allowFiltering: true,
            dataSource: country,
            fields: {text:"countryName",value:"countryName"},
            query: new Query(),
            actionComplete: () => false
            }
        },
          cTemplate: function () {
          return { template : Vue.component('columnTemplate',{
             template: `<div class="image">
                    <img :src="image" :alt="altImage"/>
                </div>`,
                data: function() {
                    return {
                        data: {}
                    }
                },
                computed: {
                    image: function() {
                        return './' + this.data.EmployeeID + '.png'
                    },
                    altImage: function() {
                        return this.data.EmployeeID
                    }
                }
          })
          }
          },
    collaborator: {},
    titleCollaboratorEventHandler: '',
    commands: [
        // { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Dettagli', id: 'Dettagli', target: '.e-column', buttonOption: { iconCss: 'e-icons e-folder-open', cssClass: 'e-flat' } },
        { type: 'Elimina', id: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
      ],
    contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
      'Copy','Save', 'PdfExport', 'ExcelExport', 'CsvExport', 'FirstPage', 'PrevPage',
      'LastPage', 'NextPage',  'Group', 'Ungroup', {text: 'Dettagli', target: '.e-content', id: 'Dettagli', iconCss: 'e-icons e-folder-open' },
        {
          text: 'Elimina', tooltipText: 'Delete', iconCss: 'e-icons e-delete', id: 'DeleteCustom',
        },
      ],
    pageSettings: { pageSize: 25, pageSizes: [25,50,100,200], pageCount: 5 },
    showModalSettingPdfExport: false,
    editSettings: { showDeleteConfirmDialog: true, allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' },
    toolbar: [
      {
        text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
      },
      'ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Print'], // { text: 'Dettagli', tooltipText: 'Dettagli', prefixIcon: 'e-expand', id: 'Dettagli' }
    toolbarOptions: ['ExcelExport'],
    ddData: [{ value: 1000, text: '1,000 Rows and 11 Columns' }, { value: 10000, text: '10,000 Rows and 11 Columns' }],
    ddValue: 10000,
    stTime: null,
    loadTime: null,
    dReady: false,
    isDataChanged: true,
    fields: { text: 'text', value: 'value' },
    collaboratorNameRules: { required: true, minLength: [(args) => {return args['value'].length >= 3}, 'Minimo 3 Caratteri'] },
    collaboratorSurnameRules: { required: true, minLength: [(args) => {return args['value'].length >= 3}, 'Minimo 3 Caratteri'] },
    statusTemplate() {
      return {
        template: Vue.component('statusTemplate', {
          components: {
            BBadge,
          },
          data() { return { data: {}, } },
          template: `  <div>
                            <div class="e-grid-empimg">
                                <b-badge>{{ data.stato }}</b-badge>
                            </div>
                        </div>`
        }),
      }
    },
    editRoleTemplate() {
      return {
        template: Vue.component('editRoleTemplate', {
          components: {
            BBadge,
            vSelect,
          },
          data() { return { data: {}, option: [], modelData: [] }},
          computed: {

          },
          mounted() {
            // this.data = this.data.ruolo.split('/')
            this.modelData.push(...this.data)
            // this.modelData =[...this.data]
          },
          methods: {
            prepareRole() {
              this.data = this.modelData.join('#')
              // console.log(this.data)
            },
            async loadRole() {
              await axios.post(process.env.VUE_APP_URL_API_SERVER, {},
                {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                    classe: 'Acl',
                    metodo: 'getProfili',
                    ACL: '',
                  },
                }).then(res => {
                this.option = res.data.permissions.map(role => role.ruolo)
              })
                .catch(e => e)
            }
          },
          template: `<div style="z-index: 9999 !important;">
                          <v-select
                            id="ruolo"
                            v-model="modelData"
                            multiple
                            placeholder="Seleziona ruolo/i"
                            :options="option"
                            @open="loadRole"
                            @input="prepareRole"
                          />
                        </div>`,
        }),
      }
    },
    roleTemplate() {
      return {
        template: Vue.component('roleTemplate', {
          components: {
            BBadge,
          },
          data() { return { data: {} }},
          computed: {
            myArray() {
              // console.log(this.data.ruolo)
              return this.data.ruolo.split('/')
            },
          },
          template: `<div>
                            <div class="e-grid-empimg">
                                <b-badge v-for="(role, index) in myArray" :key="index" class="mr-25" :variant="'primary'">{{ role }} </b-badge>
                            </div>
                        </div>`,
        }),
      }
    },
    empTemplate() {
      return {
        template: Vue.component('empTemplate', {
          data() { return { data: {}, usermale: 'usermale', userfemale: 'userfemale' } },
          template: `<div>
                            <div class="e-grid-empimg">
                                <span :class="{'e-userimg': true, 'sf-grid-icon-Male': data.EmployeeImg == usermale, 'sf-grid-icon-FeMale': data.EmployeeImg == userfemale}"></span>
                                </span>
                            </div>
                            <span id="Emptext">{{data.Employees}}</span>
                        </div>`,
        }),
      }
    },
    coltemplate() {
      return {
        template: Vue.component('coltemplate', {
          data() { return { data: {} } },
          template: '<div><img src="src/grid/images/Map.png" class="e-image" :alt="data.Location"/> <span id="locationtext">{{data.Location}}</span></div>',
        }),
      }
    },

    /* statusTemplate() {
      return {
        template: Vue.component('statusTemplate', {
          data() { return { data: {}, active: 'Active', inactive: 'Inactive' } },
          template: '<div id="status" :class="{\'statustemp\': true, \'e-activecolor\': data.Status == active, \'e-inactivecolor\': data.Status == inactive}">\
             <span :class="{\'statustxt\': true, \'e-activecolor\': data.Status == active, \'e-inactivecolor\': data.Status == inactive}">{{data.Status}}</span></div>',
        }),
      }
    }, */
    trustTemplate() {
      return {
        template: Vue.component('trustTemplate', {
          data() {
            return {
              data: {}, Perfect: 'Perfect', Sufficient: 'Sufficient', Insufficient: 'Insufficient',
            }
          },
          computed: {
            image() {
              return `src/grid/images/${this.data.Trustworthiness}.png`
            },
          },
          template: '<div><img style="width: 31px; height: 24px" :src="image" :alt="data.Trustworthiness" /><span id="Trusttext">{{data.Trustworthiness}}</span></div>',
        }),
      }
    },
    nameAvatar() {
      return {
        template: Vue.component('nameAvatar', {
          data() {
            return {
              data: {},
            }
          },
          computed: {
            image() {
              return this.data.avatar
            },
          },
          template: '<div><img style="width: 30px; height: 30px; border-radius: 100%; margin-right: 10px" :src="image" /><span id="nome">{{data.nome}}</span></div>',
        }),
      }
    },
    ratingTemplate() {
      return {
        template: Vue.component('ratingTemplate', {
          data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
          template: '<div id="status" class="rating">\
                        <span v-for="i in item" :class="{checked: i <= data.Rating, star: true}"></span>\
                      </div>',
        }),
      }
    },

    progessTemplate() {
      return {
        template: Vue.component('progessTemplate', {
          data() { return { data: {}, inactive: 'Inactive' } },
          computed: {
            width() {
              if (this.data.Software <= 20) {
                this.data.Software += 20
              }
              return `${this.data.Software}%`
            },
          },
          template: `<div id="myProgress" class="pbar">
                          <div id="myBar" :class="{'bar': true, 'progressdisable': data.Status == inactive}" :style="{'width': width}">
                            <div id="label" class="barlabel" v-html=width ></div>
                          </div>
                        </div>`,
        }),
      }
    },
    filterOptions: {
      type: 'Menu',
    },
    filter: {
      type: 'CheckBox',
    },
    selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
  }),
  computed: {
    ciao() { return 'ciao' },
    getTradeData() {
      let dataCount = this.ddValue
      const employees = [
        'Michael', 'Kathryn', 'Tamer', 'Martin', 'Davolio', 'Nancy', 'Fuller', 'Leverling', 'Peacock',
        'Margaret', 'Buchanan', 'Janet', 'Andrew', 'Callahan', 'Laura', 'Dodsworth', 'Anne',
        'Bergs', 'Vinet', 'Anton', 'Fleet', 'Zachery', 'Van', 'King', 'Jack', 'Rose']
      const designation = ['Manager', 'CFO', 'Designer', 'Developer', 'Program Directory', 'System Analyst', 'Project Lead']
      const mail = ['sample.com', 'arpy.com', 'rpy.com', 'mail.com', 'jourrapide.com']
      const location = ['UK', 'USA', 'Sweden', 'France', 'Canada', 'Argentina', 'Austria', 'Germany', 'Mexico']
      const status = ['Active', 'Inactive']
      const trustworthiness = ['Perfect', 'Sufficient', 'Insufficient']
      const tradeData = []
      const address = ['59 rue de lAbbaye', 'Luisenstr. 48', 'Rua do Paço, 67', '2, rue du Commerce', 'Boulevard Tirou, 255',
        'Rua do mailPaço, 67', 'Hauptstr. 31', 'Starenweg 5', 'Rua do Mercado, 12',
        'Carrera 22 con Ave. Carlos Soublette #8-35', 'Kirchgasse 6',
        'Sierras de Granada 9993', 'Mehrheimerstr. 369', 'Rua da Panificadora, 12', '2817 Milton Dr.', 'Kirchgasse 6',
        'Åkergatan 24', '24, place Kléber', 'Torikatu 38', 'Berliner Platz 43', '5ª Ave. Los Palos Grandes', '1029 - 12th Ave. S.',
        'Torikatu 38', 'P.O. Box 555', '2817 Milton Dr.', 'Taucherstraße 10', '59 rue de lAbbaye', 'Via Ludovico il Moro 22',
        'Avda. Azteca 123', 'Heerstr. 22', 'Berguvsvägen  8', 'Magazinweg 7', 'Berguvsvägen  8', 'Gran Vía, 1', 'Gran Vía, 1',
        'Carrera 52 con Ave. Bolívar #65-98 Llano Largo', 'Magazinweg 7', 'Taucherstraße 10', 'Taucherstraße 10',
        'Av. Copacabana, 267', 'Strada Provinciale 124', 'Fauntleroy Circus', 'Av. dos Lusíadas, 23',
        'Rua da Panificadora, 12', 'Av. Inês de Castro, 414', 'Avda. Azteca 123', '2817 Milton Dr.']
      const employeeimg = ['usermale', 'userfemale']
      if (typeof dataCount === 'string') {
        dataCount = parseInt(dataCount, 10)
      }
      for (let i = 1; i <= dataCount; i++) {
        const code = 10000
        tradeData.push({
          EmployeeID: code + i,
          Employees:
                        `${employees[Math.floor(Math.random() * employees.length)]} ${employees[Math.floor(Math.random() * employees.length)]}`,
          Designation: designation[Math.floor(Math.random() * designation.length)],
          Location: location[Math.floor(Math.random() * location.length)],
          Status: status[Math.floor(Math.random() * status.length)],
          Trustworthiness: trustworthiness[Math.floor(Math.random() * trustworthiness.length)],
          Rating: Math.floor(Math.random() * 5),
          Software: Math.floor(Math.random() * 100),
          EmployeeImg: employeeimg[Math.floor(Math.random() * employeeimg.length)],
          CurrentSalary: Math.floor((Math.random() * 100000)),
          Address: address[Math.floor(Math.random() * address.length)],
        })
        const employee = 'Employees'
        const emp = tradeData[i - 1][employee]
        const sName = emp.substr(0, emp.indexOf(' ')).toLowerCase()
        const empmail = 'Mail'
        tradeData[i - 1][empmail] = `${sName + (Math.floor(Math.random() * 100) + 10)}@${mail[Math.floor(Math.random() * mail.length)]}`
      }
      this.stTime = performance.now()
      return tradeData
    },
  },
  methods: {
    addToCollaborator(data) {
      // console.log('prima di unshift', data)
      this.collaborators.unshift(data)
    },
    updateCollaborator(data) {
      const indexToFind = this.collaborators.findIndex(el => el.id_user === data.id_user)
      this.collaborators.splice(indexToFind, 0)
      this.collaborators[indexToFind] = { ...data }
    },
    prepareSidebarToEdit(user, title) {
      // console.log(this.collaborators)
      if (title === 'Modifica Collaboratore') {
        this.$refs['sidebar-collaborators'].setTitle(title)
        const roles = user.ruolo.split('/')
        const userEdited = { ...user }
        userEdited.ruolo = [...roles]
        // console.log(userEdited)
        this.collaborator = { ...userEdited }
        // console.log('collaborator', this.collaborator)
      } else {
        this.$refs['sidebar-collaborators'].setTitle(title)
        this.collaborator = {
          avatar: '',
          nome: '',
          cognome: '',
          ruolo: '',
          email: '',
          codice_fiscale: '',
          partita_iva: '',
          stato: '',
          cellulare: '',
          ACL: '',
          data_nascita: '',
          stato_nascita: '',
          prov_nascita: '',
          citta_nascita: '',
          sesso: '',
          codice_sdi: '',
          ragione_sociale: '',
        }
      }
      // console.log('prepareSidebarToEdit', user)
      // console.log('prepareSidebarToEdit', title)
      // console.log('editCustomer', user)
      // console.log('collaborator', this.collaborator)
      this.$refs['sidebar-collaborators'].setFormToEdit(this.collaborator)
    },
    removeCollaborator(collaboratorToRemove, fromTable) {
      // console.log(collaboratorToRemove)
      // console.log('row', this.rows)
      if (fromTable === 'table') {
        const indexToSplice = this.rows.findIndex(r => r.id === collaboratorToRemove.id_user)
        this.rows.splice(indexToSplice, 1)
      } else {
        const indexToSplice = this.rows.findIndex(r => r.id === collaboratorToRemove.id_user)
        this.rows.splice(indexToSplice, 1)
      }
    },
    dataBound: function() {
      // console.log(this.$refs.overviewgrid)
      this.$refs.overviewgrid.autoFitColumns([ 'ruolo', 'email', 'nome', 'cognome', 'codice_fiscale' ]);
    },
    commandColumm(args) {
      // console.log('commandColumm', args)
      if(args.commandColumn.type === 'Dettagli') {
          const roles = args.rowData.ruolo.split('/')
            const userEdited = { ...args.rowData }
          userEdited.ruolo = [...roles]
          // console.log(userEdited)
          this.collaborator = { ...userEdited }
          this.$refs['sidebar-collaborators'].setTitle('Modifica Collaboratore')

          this.$refs['sidebar-collaborators'].setFormToEdit(userEdited)
          this.$refs['sidebar-collaborators'].openSidebar()
        }
        if (args.commandColumn.type === 'Elimina') {
        // console.log([{ id: args.rowData.id }])
        this.alertAndConfirm([{ id: args.rowData.id }])
      }
    },
    contextMenuClick(args) {
        // console.log('contextMenuClick', args)
        if(args.item.id === 'Dettagli') {
          const roles = args.rowInfo.rowData.ruolo.split('/')
          const userEdited = { ...args.rowInfo.rowData }
          userEdited.ruolo = [...roles]
          // console.log(userEdited)
          this.collaborator = { ...userEdited }
          this.$refs['sidebar-collaborators'].setTitle('Modifica Collaboratore')
          this.$refs['sidebar-collaborators'].setFormToEdit(userEdited)
          this.$refs['sidebar-collaborators'].openSidebar()
        } else if (args.item.id === 'DeleteCustom') {
          this.alertAndConfirm([{ id: args.rowInfo.rowData.id }])
        }
    },
    alertAndConfirm(id) {
      // console.log('sono su alert confirm id', id)
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'Verranno rimossi permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          this.deleteCollaboratore(id)
        }
      })
    },
    deleteCollaboratore(id) {
      let recordToDelete = []
      if (id === undefined) recordToDelete = this.$refs.overviewgrid.getSelectedRecords().map(el => ({ id: el.id }))
      else recordToDelete = id
      console.log(recordToDelete, 'wow')
    },
    closeModal() {
      this.showModalSettingPdfExport = false
    },
    actionTableBegin() {
    },
    async actionTableComplete(action) {
      if (action.requestType === 'save') {
        let prova = { ...action.data }
        const telefono = prova.cellulare
        delete prova['cellulare']
        prova.telefono = telefono
        const dataParsing = JSON.stringify([{ ...prova }])
        // console.log(dataParsing)
        await axios.post(process.env.VUE_APP_URL_API_SERVER, dataParsing,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: String(JSON.parse(sessionStorage.getItem('activeAgency')).value),
            classe: 'Utente',
            metodo: 'setProfiloPersona',
            ACL: 'vat',
          },
        }).then(res => {
        // console.log('res setProfiloPersona', res)
        if (res.data.esito === 'OK') {
          const user = res.data.persona[0]
          const roles = res.data.persona[0].ruolo
          const newRoles = roles.split('#').join('/')
          user.ruolo = newRoles
          // this.$emit('update-collaborator', user)
          // this.$emit('update-collaborator', res.data.persona[0])
          // this.optionDiProva = { ...res.data.persona[0], isChangedImage: false }
          // console.log(res)
          this.alertSuccess()
          // this.$refs['sidebar-collaborators'].hide()
        }
      })
        .catch(e => e)
      }
    },
    alertSuccess() {
      this.$swal({
        title: 'Salvataggio Effettuato Correttamente',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    pdfHeaderQueryCellInfo() {
      // args.cell.row.pdfGrid.repeatHeader = true;
    },
    toolbarClick(args) {
      // console.log(args)
      if (args.item.id === 'Dettagli') {
        this.$refs['sidebar-collaborators'].openSidebar()
      }
      if (args.item.id === 'Elimina' && this.$refs.overviewgrid.getSelectedRecords().length > 0) {
          // console.log('delete')
          this.alertAndConfirm()
      }
      if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        this.showModalSettingPdfExport = true
          const exportProperties = {
              //you can customize the name as per your requirement
              fileName: 'prova.pdf',
            }
          this.$bvModal.show('setting-pdf-export-modal')
          this.$refs.overviewgrid.pdfExport(exportProperties)
          // this.$refs.overviewgrid.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          this.$refs.overviewgrid.excelExport()
      }
      if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          this.$refs.overviewgrid.csvExport()
      }
     },
    valueChange(args) {
      this.$refs.dropdown.$el.ej2_instances[0].closePopup()
      this.$refs.overviewgrid.showSpinner()
      this.isDataChanged = true
      this.stTime = null
      const contentElement = this.$refs.overviewgrid.ej2Instances.contentModule.content
      this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage = 1
      contentElement.scrollLeft = 0
      contentElement.scrollTop = 0
      // console.log(this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage)
      this.stTime = performance.now()
      this.ddValue = args.value
      this.$refs.overviewgrid.hideSpinner()
    },
    load() {
      const proxy = this
      this.$refs.overviewgrid.$el.ej2_instances[0].on('data-ready', () => {
        proxy.dReady = true
      })
      this.$refs.overviewgrid.$el.addEventListener('DOMSubtreeModified', () => {
        if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
          const e = performance.now() - proxy.stTime
          proxy.loadTime = `Load Time: <b>${e.toFixed(0)}</b><b>ms</b>`
          proxy.stTime = null
          proxy.dReady = false
          proxy.isDataChanged = false
          // proxy.$refs.msgelement.classList.remove('e-hide')
        }
      })
    },
  },
  provide: {
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Aggregate],
  },

})

</script>
<style scoped>
/* @import "../../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css"; */
</style>
