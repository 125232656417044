<template>
  <b-modal
    id="setting-pdf-export-modal"
    ref="setting-pdf-export-modal"
    centered
    title="Impostazioni PDF"
    ok-only
    ok-title="Scarica"
    @ok="emitConfirm"
  >
    <b-card-text>
      Croissant jelly-o halvah chocolate sesame snaps.
      Brownie caramels candy canes chocolate cake marshmallow icing lollipop I love.
      Gummies macaroon donut caramels biscuit topping danish.
    </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: this.show,
    }
  },
  computed: {
    showModal1: {
      get: () => this.show,
      // set: value => this.$emit('modal-pdf-setting-export-close', value),
      set: value => {
        // console.log(value)
        this.$emit('modal-pdf-setting-export-close', value)
      },
    },
  },
  methods: {
    emitConfirm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$emit('pdf-export-confirm')
    },
  },
}
</script>

<style>

</style>
