<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event-my"
      ref="sidebar-collaborators"
      v-model="show"
      sidebar-class="sidebar-xl"
      bg-variant="white"
      backdrop-variant="dark"
      backdrop
      :no-close-on-backdrop="true"
      shadow
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ title }}
          </h5>
          <div>
            <feather-icon
              v-if="title === 'Modifica Collaboratore'"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event', form); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide()"
            />
          </div>
        </div>
        <!-- {{ collaborator }} -->
        <validation-observer
          ref="collaborator-anagrafic-form-rules"
          tag="form"
        >
          <b-row class="m-1">
            <b-col
              cols="12"
              xl="8"
            >
              <b-form-group
                label="Ruolo/i"
                label-for="assign-roles"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Ruolo"
                  rules="required"
                >
                  <v-select
                    v-model="collaborator.ruolo"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="title"
                    placeholder="Seleziona ruolo/i"
                    :options="option"
                    @open="loadRole"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ open, searching, loading }">
                      Caricamento Ruoli
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="title !== 'Modifica Collaboratore'"
              md="6"
              xl="4"
            >
              <b-form-group
                label="Email d'accesso"
                label-for="email-login"
                label-class="font-weight-bolder"
                class="mr-md-1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email-login"
                    v-model="collaborator.email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="email d'accesso"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              xl="4"
            >
              <b-form-group
                label="Numero di Telefono"
                label-for="collaborator-phone"
                label-class="font-weight-bolder"
              >
                <b-form-input
                  id="collaborator-phone"
                  v-model="collaborator.cellulare"
                  placeholder="+39"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              xl="4"
            >
              <b-form-group
                label="Nome"
                label-for="collaborator-first-name"
                label-class="font-weight-bolder"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="nome"
                  rules="required"
                >
                  <b-form-input
                    id="collaborator-first-name"
                    v-model="collaborator.nome"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nome"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              xl="4"
            >
              <b-form-group
                label="Cognome"
                label-for="collaborator-surname"
                label-class="font-weight-bolder"
              >
                <b-form-input
                  id="collaborator-surname"
                  v-model="collaborator.cognome"
                  placeholder="Cognome"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="5"
              xl="4"
            >
              <b-form-group
                label="Sesso"
                label-for="collaborator-gender"
                label-class="font-weight-bolder"
              >
                <div
                  id="collaborator-gender"
                  class="demo-inline"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Sesso"
                    rules="required"
                  >
                    <b-form-radio
                      v-model="collaborator.sesso"
                      name="M"
                      value="M"
                    > M
                    </b-form-radio>
                    <b-form-radio
                      v-model="collaborator.sesso"
                      name="F"
                      value="F"
                    > F
                    </b-form-radio>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols="7"
              xl="4"
            >
              <!-- <validation-provider
                #default="{ errors }"
                name="Data di Nascita"
                rules="required"
              > -->
              <validation-provider
                #default="{ errors }"
                name="Data di Nascita"
                rules="required"
              >
                <b-form-group
                  :label="$t('personalProfile.information.placeholder.dateBirth')"
                  label-for="collaborator-dateofBith"
                  label-class="font-weight-bolder"
                >
                  <flatPickr
                    id="collaborator-dateofBith"
                    v-model="collaborator.data_nascita"
                    class="form-control"
                    :config="configFlatPicker"
                    :placeholder="$t('personalProfile.information.placeholder.dateBirth')"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Nazionalità"
                rules="required"
              >
                <b-form-group
                  label="Nazionalità"
                  label-for="collaborator-nation"
                  label-class="font-weight-bolder"
                  class=""
                >
                  <div
                    id="collaborator-nation"
                    class="demo-inline"
                  >
                    <b-form-radio
                      v-model="collaborator.stato_nascita"
                      name="italian"
                      value="IT"
                    > Italiana
                    </b-form-radio>
                    <b-form-radio
                      v-model="collaborator.stato_nascita"
                      name="not-italian"
                      value="EE"
                    > Estera
                    </b-form-radio>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="collaborator.stato_nascita !== 'EE'"
              cols="12"
              md="6"
              xl="4"
            >
              <b-form-group
                :label="'Prov di Nascita'"
                label-for="anagrafic-city-born"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="'Prov di Nascita'"
                  rules="length:2"
                >
                  <v-select
                    v-model="collaborator.prov_nascita"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="prov"
                    :clearable="false"
                    taggable
                    :reduce="prov => prov.prov"
                    filterable
                    :options="province"
                    :create-option="comune => ({ prov: comune })"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="collaborator.stato_nascita !== 'EE'"
              cols="12"
              md="6"
              xl="4"
            >
              <b-form-group
                :label="'Comune di Nascita'"
                label-for="anagrafic-city-born2"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="'Comune di Nascita'"
                  rules="required"
                >
                  <v-select
                    v-model="collaborator.citta_nascita"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="comune"
                    :clearable="false"
                    filterable
                    taggable
                    :reduce="comune => comune.comune"
                    :options="comuniComp"
                    :create-option="comune => ({ comune: comune })"
                  /><!--@input="calculatefiscalcode2"-->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="collaborator.stato_nascita === 'EE'"
              cols="12"
              md="6"
              xl="4"
            >
              <b-form-group
                :label="'Nazione di Nascita'"
                label-for="anagrafic-city-born2"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="'Nazione di Nascita'"
                  rules="required"
                >
                  <v-select
                    v-model="collaborator.citta_nascita"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="comune"
                    :clearable="false"
                    filterable
                    taggable
                    :reduce="comune => comune.comune"
                    :options="comuniComp"
                    :create-option="comune => ({ comune: comune })"
                  /><!--@input="calculatefiscalcode2"-->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="8"
            >
              <label for="collaborator-anagrafic-fiscalCode"><b>Codice Fiscale</b></label>
              <validation-provider
                #default="{ errors }"
                name="Codice Fiscale"
                rules="required"
              >
                <b-input-group class="mb-1">
                  <b-form-input
                    id="collaborator-anagrafic-fiscalCode"
                    v-model="collaborator.codice_fiscale"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Codice Fiscale"
                  />
                  <b-input-group-append>
                    <b-button
                      v-b-modal="'calculateFiscalCode'"
                      variant="primary"
                    >
                      <!-- @click="calculateFiscalCode" -->
                      Calcola
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <calculate-fiscal-code
              @fiscalcode="addFiscalCode"
            />
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Codice SDI"
                label-for="collaborator-organization-codeSdi"
                label-class="font-weight-bolder"
              >
                <b-form-input
                  id="collaborator-organization-codeSdi"
                  v-model="collaborator.codice_sdi"
                  placeholder="Codice SDI"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              lg="6"
              class="d-flex justify-content-center"
            >
              <b-form-group
                label="Stato"
                label-class="font-weight-bolder"
              >
                <b-form-checkbox
                  v-model="collaborator.stato"
                  value="ATTIVO"
                  checked
                  unchecked-value="DISATTIVATO"
                  class="custom-control-primary align-self-end"
                  name="check-button"
                  switch
                />{{ collaborator.stato }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col
              cols="12"
              class="d-flex justify-content-end"
            >
              <b-button
                class="m-2"
                @click="registerInternalUser"
              >Salva</b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow, BCol, BSidebar, BFormGroup, BFormInput, BButton, BInputGroupAppend, BInputGroup, BFormCheckbox, BFormRadio,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
/* import {
  FormWizard, TabContent, WizardButton,
} from 'vue-form-wizard' */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required, email, url } from '@validations'
import axios from 'axios'
import { filterErrorResponseApi } from '@core/utils/filter'
import vSelect from 'vue-select'
import CodiceFiscale from '@core/fiscalcode/dist/codice.fiscale.amd'
import flatPickr from 'vue-flatpickr-component'
import { COMUNI } from '@core/fiscalcode/lists/lista-comuni'
import { PROVINCE } from '@core/fiscalcode/lists/lista-province'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'
import CalculateFiscalCode from '@/components/modal/CalculateFiscalCode.vue'
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref, toRefs } from '@vue/composition-api'
// import useColllaboratorsEventHandler from './useColllaboratorsEventHandler'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    // BFormRadio,
    // BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BFormRadio,
    flatPickr,
    // BFormSelect,

    // BDropdown,
    // BDropdownItem,

    // FormWizard,
    // TabContent,
    // WizardButton,

    ValidationProvider,
    ValidationObserver,
    vSelect,
    CalculateFiscalCode,
  },
  directives: {
    Ripple,
  },
  /* model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  }, */
  props: {
    collaborator: {
      type: Object,
      required: false,
      default: (() => {}),
    },
  },
  data() {
    return {
      required,
      email,
      url,
      show: false,
      locale: 'it',
      title: '',
      comuni: COMUNI.map(comune => ({ comune: comune[2], prov: comune[1], key: comune[0] })),
      province: Object.keys(PROVINCE).map(key => ({ comune: PROVINCE[key], prov: key, key })),
      selectedComune: {},
      configFlatPicker: {
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'Y-m-d',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian, // locale for this instance only
      },
      typeSelected: 'Person',
      selectedEdit: false,
      token: `Bearer ${sessionStorage.getItem('token')}`,
      selected: [],
      option: [],
      formReset: {
        id: -1,
        type: this.typeSelected,
        anagrafic: {
          name: '',
          surname: '',
          gender: '',
          dateOfBorn: '',
          provOfBorn: '',
          cityOfBorn: '',
          fiscalCode: '',
        },
        address: {
          id: '',
          type: this.addressSelected,
          street: '',
          number: '',
          city: '',
          prov: '',
          cap: '',
        },
        organization: {
          name: '',
          codeVat: '',
          emailPec: '',
          emailMain: '',
          emailSecondary: '',
          codeSdi: '',
          codeRa: '',
          checkBoxOrganization: [],
        },
        contact: {
          officePhone: '',
          personalPhone: '',
          homePhone: '',
          emailMain: '',
          emailSecondary: '',
          emailPec: '',
        },
        gdpr: {
          acconsent1: false,
          acconsent2: false,
        },
      },
      form: {
        id: -1,
        type: 'Person',
        anagrafic: {
          name: '',
          surname: '',
          gender: '',
          dateOfBorn: '',
          provOfBorn: '',
          cityOfBorn: '',
          fiscalCode: '',
          phone: '',
          codiceSdi: '',
          nation: '',
        },
        address: {
          id: '',
          type: this.addressSelected,
          street: '',
          number: '',
          city: '',
          prov: '',
          cap: '',
        },
        organization: {
          name: '',
          codeVat: '',
          emailPec: '',
          emailMain: '',
          emailSecondary: '',
          codeSdi: '',
          codeRa: '',
          checkBoxOrganization: [],
        },
        contact: [],
        gdpr: {
          acconsent1: false,
          acconsent2: false,
        },
      },
      addressSelected: null,
      addressToAdd: '',
      contacts: [],
      contactIndex: '',
      contactSelected: null,
      address: [
        /* {
          id: '',
          type: '',
          street: '',
          number: '',
          city: '',
          prov: '',
          cap: '',
        }, */
      ],
      checkOptions: [
        { value: 'RA', name: 'RA' },
        { value: 'Altro', name: 'Altro' },
      ],
      options: [
        { value: 'Residenza', text: 'Residenza', selected: true },
        { value: 'Fatturazione', text: 'Fatturazione' },
        { value: 'Ufficio', text: 'Ufficio' },
        { value: 'Spedizione', text: 'Spedizione' },
      ],
      optionContacts: [
        { value: 'tel', text: 'Tel', selected: true },
        { value: 'pec', text: 'Pec' },
        { value: 'email', text: 'Email' },
        { value: 'website', text: 'SitoWeb' },
        { value: 'facebook', text: 'Facebook' },
        { value: 'twitter', text: 'Twtitter' },
        { value: 'instagram', text: 'Instagram' },
        { value: 'tikTok', text: 'Tik Tok' },
      ],
      loadingWizard: false,
      fiscalCode: '',
    }
  },
  computed: {
    comuniComp() {
      if (this.collaborator.stato_nascita !== 'EE') return this.comuni.filter(comune => comune.prov === this.collaborator.prov_nascita)
      return this.comuni.filter(comune => comune.prov === 'EE')
    },
    /* newFiscalCode() {
      return this.calculatefiscalcode2()
    }, */
  },
  watch: {
    collaborator: {
      handler: 'checkFormFiscalCode',
      deep: true,
    },
  },
  mounted() {
    // this.$refs['wizard-collaborators-manage'].activateAll()
    localize(this.locale)
    /* const cf = new CodiceFiscale({
      name: 'Enzo',
      surname: 'Righi',
      gender: 'M',
      day: 24,
      month: 7,
      year: 1957,
      birthplace: 'Napoli',
      birthplaceProvincia: 'NA',
    })
    console.log('cf', cf) */
  },
  methods: {
    checkFormFiscalCode() {
      this.calculatefiscalcode2()
      /* const { fields } = this.$refs.changeProfileSettingGeneral
      console.log(fields)
      const result = Object.values(fields).every(o => o.valid === true)
      console.log('result', result) */
    },
    setTitle(title) {
      this.title = title
    },
    openSidebar() {
      this.show = true
    },
    closeSidebar() {
      this.show = false
    },
    addFiscalCode(obj) {
      // console.log(obj)
      this.collaborator.codice_fiscale = obj.fiscalCode
      this.collaborator.nome = obj.anagrafic.name
      this.collaborator.cognome = obj.anagrafic.surname
      this.collaborator.data_nascita = obj.anagrafic.dateOfBorn
      this.collaborator.stato_nascita = obj.anagrafic.nation
      this.collaborator.prov_nascita = obj.anagrafic.provOfBorn
      this.collaborator.citta_nascita = obj.anagrafic.cityOfBorn
      this.collaborator.sesso = obj.anagrafic.gender
      // console.log('obj', obj)
      // console.log('collaborator', this.collaborator)
    },
    calculateFiscalCode() {
      const splitDateToArray = this.form.anagrafic.dateOfBorn.split('-')
      const year = splitDateToArray[0]
      const month = splitDateToArray[1]
      const day = splitDateToArray[2]
      // console.log(this.form.anagrafic.dateOfBorn)
      // console.log(this.form.anagrafic.dateOfBorn.split('-'))
      try {
        const cf = new CodiceFiscale({
          name: this.form.anagrafic.name,
          surname: this.form.anagrafic.surname,
          gender: this.form.anagrafic.gender.toUpperCase(),
          day,
          month,
          year,
          birthplace: this.form.anagrafic.cityOfBorn,
          birthplaceProvincia: this.form.anagrafic.provOfBorn.toUpperCase(),
        })
        this.form.anagrafic.fiscalCode = cf.code
      } catch (err) {
        // console.log('errore', err)
      }
      // console.log(cf)
    },
    prepareRoleForServer() {
      // console.log(this.selected.map(role => role.title))
      if (this.title === 'Aggiungi Collaboratore') return this.collaborator.ruolo.reduce((role, acc) => acc + String('#') + role)
      if (this.title === 'Modifica Collaboratore') return this.collaborator.ruolo.reduce((role, acc) => acc + String('#') + role)
      return ''
    },
    async loadRole() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Acl',
            metodo: 'getProfili',
            ACL: '',
          },
        }).then(res => {
        this.option = res.data.permissions.map(role => role.ruolo)
      })
        .catch(e => e)
    },
    setFormToEdit(value) {
      // console.log('setForm', value)
      // console.log('this.formReset', this.formReset)
      this.form.anagrafic.name = value.fullName
      this.form.id = value.id
      if (value.type === 'Person') {
        this.form.type = value.type
        this.form.anagrafic.name = value.fullName
        this.form.anagrafic.fiscalCode = value.fiscalCodeOrIva
        this.form.contact.emailMain = value.emailMain
        this.form.contact.personalPhone = value.personalPhone
      } else if (value.type === 'Organization') {
        this.form.type = value.type
        this.form.organization.name = value.fullName
        this.form.contact.emailMain = value.emailMain
        this.form.anagrafic.fiscalCode = value.fiscalCodeOrIva
      }
    },
    resetForm() {
      // console.log(this.$refs['wizard-collaborators-manage'])
      // console.log('prima', this.form)
      // console.log('prima formReset', this.formReset)
      this.form = JSON.parse(JSON.stringify(this.formReset))
      // console.log('dopo', this.form)
      // console.log('dopo formReset', this.formReset)
      setTimeout(() => {
        this.$refs['wizard-collaborators-manage'].reset()
        this.$refs['wizard-collaborators-manage'].activateAll()
        this.$refs['collaborator-anagrafic-form-rules'].reset()
      }, 350)
    },
    validationAnagrafic1() {
      return new Promise((resolve, reject) => {
        this.$refs['collaborator-anagrafic-form-rules'].validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    onComplete() {
    },
    setLoading(value) {
      /*       console.log('valueee', value)
      console.log('loadingWizard', this.loadingWizard) */
      this.loadingWizard = value
    },
    handleValidation(isValid) {
      // console.log('Tab: ', tabIndex, ' valid: ', isValid, 'loadingwizard ', this.loadingWizard)
      // console.log(isValid)
      if (isValid) this.registerInternalUser()
    },
    validateAsync() {
      // console.log('dentro  validate syncsss')
      return new Promise(resolve => {
        setTimeout(() => {
          // console.log('dentro set timeout')
          resolve(true)
        }, 2000)
      })
    },
    validationAnagrafic() {
      this.$refs['collaborator-anagrafic-form-rules'].validate().then(success => {
        if (success) {
          this.registerInternalUser()
        }
      })
    },
    async registerInternalUser() {
      // console.log('register axios')
      let data = {}
      if (this.title === 'Aggiungi Collaboratore') {
        const roles = this.prepareRoleForServer()
        data = {
          name: this.collaborator.nome,
          cognome: this.collaborator.cognome,
          email: this.collaborator.email,
          ruolo: roles,
          cellulare: this.collaborator.cellulare,
          piano: 'FREE',
          lingua: 'IT',
          ACL: '',
          data_nascita: this.collaborator.data_nascita,
          stato_nascita: this.collaborator.stato_nascita,
          prov_nascita: this.collaborator.prov_nascita,
          citta_nascita: this.collaborator.citta_nascita,
          sesso: this.collaborator.sesso,
          codice_sdi: this.collaborator.codice_sdi,
          codice_fiscale: this.collaborator.codice_fiscale,
          ragione_sociale: this.collaborator.ragione_sociale,
          avatar: '',
        }
        // console.log('registrazione', data)
        this.registration(data)
      }
      if (this.title === 'Modifica Collaboratore') {
        const roles = this.prepareRoleForServer()
        data = {
          avatar: this.collaborator.avatar,
          nome: this.collaborator.nome,
          cognome: this.collaborator.cognome,
          // email: this.collaborator.email,
          ruolo: roles,
          // ruolo: this.collaborator.ruolo,
          telefono: this.collaborator.cellulare,
          data_nascita: this.collaborator.data_nascita,
          stato_nascita: this.collaborator.stato_nascita,
          prov_nascita: this.collaborator.prov_nascita,
          citta_nascita: this.collaborator.citta_nascita,
          sesso: this.collaborator.sesso,
          codice_sdi: this.collaborator.codice_sdi,
          codice_fiscale: this.collaborator.codice_fiscale,
          id_user: this.collaborator.id_user,
          partita_iva: this.collaborator.partita_iva,
          // ragione_sociale: this.collaborator.ragione_sociale,
          stato: 'ATTIVO', // da sostituire con partita iva
        }
        // console.log('Modifica', data)
        this.editCollaborator(data)
      }
      // console.log(roles)
      // console.log('data', data)
      // console.log('surname', this.surname)
      // console.log('email', this.email)
      // console.log('role', this.role)
      // console.log('phone', this.phone)
      // console.log('plan', this.plan)
      // console.log(JSON.parse(sessionStorage.getItem('activeAgency')).value)
    },
    async registration(data) {
      // console.log('nuovo collaboratore', data)
      await axios.post(process.env.VUE_APP_URL_API_SERVER,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Utente',
            metodo: 'registrazioneUtenteInterno',
          },
        }).then(res => {
        // console.log('sei dentro lo store/resetPassword')
        // console.log('registrazioneUtenteInterno, res', res)
        if (res.data.esito === 'OK') {
          const resp = { ...res.data.persona }
          resp.id_user = res.data.id_user
          // console.log('resssp', resp)
          this.$emit('add-new-collaborator', resp)
          this.$swal({
            title: res.data.messaggio,
            // text: 'Controlla la posta per confermare',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        if (res.data.esito === 'KO') {
          this.errorsResponse = filterErrorResponseApi(res.data.messaggio)
          // console.log('2', this.errorsResponse)
          this.$swal.fire({
            title: '<strong><u>Errore</u></strong>',
            icon: 'error',
            html: this.errorsResponse.map(item => `<p class="text-left">${item}</p>`).join(''),
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          // this.$refs['wizard-collaborators-manage'].prevTab()
        }
      })
        .catch(e => {
          // console.log(e)
          if (e) {
            this.$swal({
              title: 'Errore',
              text: 'Controlla i dati inseriti',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    async editCollaborator(data) {
      // console.log('edit', data)
      const dataParsing = JSON.stringify([{ ...data }])
      // console.log('regsitrazione parsing', dataParsing)
      await axios.post(process.env.VUE_APP_URL_API_SERVER, dataParsing,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: String(JSON.parse(sessionStorage.getItem('activeAgency')).value),
            classe: 'Utente',
            metodo: 'setProfiloPersona',
            ACL: 'vat',
          },
        }).then(res => {
        // console.log('res setProfiloPersona', res)
        if (res.data.esito === 'OK') {
          const user = res.data.persona[0]
          const roles = res.data.persona[0].ruolo
          const newRoles = roles.split('#').join('/')
          user.ruolo = newRoles
          this.$emit('update-collaborator', user)
          // this.$emit('update-collaborator', res.data.persona[0])
          // this.optionDiProva = { ...res.data.persona[0], isChangedImage: false }
          // console.log(res)
          this.alertSuccess()
          this.$refs['sidebar-collaborators'].hide()
        }
      })
        .catch(e => e)
    },
    alertSuccess() {
      this.$swal({
        title: 'Salvataggio Effettuato Correttamente',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    calculatefiscalcode2() {
      // console.log('this.collaborator', this.collaborator)
      const splitDateToArray = this.collaborator.data_nascita.split('-')
      const year = splitDateToArray[0]
      const month = splitDateToArray[1]
      const day = splitDateToArray[2]
      // console.log(this.form.anagrafic.dateOfBorn)
      // console.log(this.form.anagrafic.dateOfBorn.split('-'))
      // console.log(this.optionsLocal)
      try {
        const cf = new CodiceFiscale({
          name: this.collaborator.nome,
          surname: this.collaborator.cognome,
          gender: this.collaborator.sesso.toUpperCase(),
          day,
          month,
          year,
          birthplace: this.collaborator.citta_nascita,
          birthplaceProvincia: this.collaborator.stato_nascita === 'EE' ? 'EE' : this.collaborator.prov_nascita.toUpperCase(),
        })
        this.fiscalCode = cf.code
        this.collaborator.codice_fiscale = this.fiscalCode
      } catch (e) { return '' }
      // console.log('code fiscale', this.fiscalCode)
      return this.fiscalCode
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
