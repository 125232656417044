<template>
  <b-tabs
    ref="manage-accounts"
    pills
  >
    <!--   <b-tabs
    ref="manage-accounts"
    vertical
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  > -->

    <!-- general tab -->
    <!-- <b-tab
      ref="createInternalUser"
    >

      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Crea Account Interno</span>
      </template>
      <CreateInternalAccount />
    </b-tab> -->
    <!--/ general tab -->

    <!-- change password tab -->
    <!-- <b-tab>

      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Collaboratori</span>
      </template>

      <Collaborators2 :collaborators="listCollaborators" />
    </b-tab> -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Collaboratori</span>
      </template>

      <CollaboratorsNew :collaborators="listCollaborators" />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->s
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Profili</span>
      </template>

      <Profile2
        :permissions="permissions"
        @push-profile="pushProfile"
      />
    </b-tab>
    <b-tab lazy>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold mr-3">Action History</span>
      </template>

      <History
        :login-history="history"
        :action-history="modificheHistory"
      />
    </b-tab>
    <b-tab lazy>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold mr-3">Login History</span>
      </template>

      <login-history
        :login-history="history"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import axios from 'axios'
// import CreateInternalAccount from '@/views/pages/settings/generalSettings/userDetails/pages/CreateInternalAccount.vue'
// import Collaborators2 from '@/views/pages/settings/generalSettings/userDetails/pages/Collaborators2.vue'
import CollaboratorsNew from '@/views/pages/settings/generalSettings/userDetails/pages/CollaboratorsNew.vue'
import Profile2 from '@/views/pages/settings/generalSettings/userDetails/pages/Profile2.vue'
import LoginHistory from '@/views/pages/settings/generalSettings/userDetails/pages/LoginHistory.vue'
import History from '@/views/pages/settings/generalSettings/userDetails/pages/History.vue'
// import History2 from '@/views/pages/settings/generalSettings/userDetails/pages/History2.vue'
// import ActionHistory from '@/views/pages/settings/generalSettings/userDetails/pages/ActionHistory.vue'

export default {
  components: {
    BTabs,
    BTab,
    // CreateInternalAccount,
    // Collaborators2,
    CollaboratorsNew,
    Profile2,
    History,
    // History2,
    LoginHistory,
  },
  data() {
    return {
      solidColor: [
        { bg: 'primary', title: 'Registra Account', routeToGo: 'register-account-internal' },
        { bg: 'primary', title: 'Collaboratori', routeToGo: 'general-settings-users-collaborators2' },
        /* { bg: 'primary', title: 'Ruoli', routeToGo: 'general-settings-users-roles' }, */
        { bg: 'primary', title: 'Profili2', routeToGo: 'general-settings-users-profiles2' },
        /* { bg: 'primary', title: 'Gruppi', routeToGo: 'general-settings-users-groups' }, */
        { bg: 'primary', title: 'Login History', routeToGo: 'general-settings-users-login-history' },
        { bg: 'primary', title: 'Action History', routeToGo: 'general-settings-users-action-history' },
      ],
      historyServer: [],
      modificheHistoryServer: [],
      permissions: [],
      collaborators: [],
    }
  },
  computed: {
    history() {
      return this.historyServer
    },
    modificheHistory() {
      return this.modificheHistoryServer
    },
    listCollaborators() {
      return this.collaborators
    },
  },
  mounted() {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Utente',
          metodo: 'getLoginHistory',
          ACL: 'invoice-list',
        },
      }).then(res => {
      // console.log(res)
      if (res.data.esito === 'OK') {
        this.historyServer = res.data.login
      }
    }).catch(e => e)
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Utente',
          metodo: 'getModificheHistory',
          ACL: 'invoice-list',
        },
      }).then(res => {
      if (res.data.esito === 'OK') {
        this.modificheHistoryServer = res.data.storico
      }
    }).catch(e => e)
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Acl',
          metodo: 'getPermissions',
          ACL: '',
        },
      }).then(res => {
      // console.log(res)
      this.permissions = res.data.permissions.map(role => ({ title: role.ruolo, id: role.id }))
    })
      .catch(e => e)
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Utente',
          metodo: 'getUtentiInterni',
          ACL: '',
        },
      }).then(res => {
      // console.log('utenti interni', res)
      if (res.data.esito === 'OK') {
        this.collaborators = res.data.utenti.map(user => ({
          avatar: user.avatar ? user.avatar : '',
          nome: user.nome,
          cognome: user.cognome,
          ragione_sociale: user.ragione_sociale,
          ruolo: user.ruolo.split('#').join('/'),
          email: user.email,
          codice_fiscale: user.codice_fiscale,
          partita_iva: user.partita_iva,
          stato: user.stato_user,
          cellulare: user.cellulare,
          id_user: user.id_user,
          id_persona: user.id_persona,
          ACL: '',
          data_nascita: user.data_nascita,
          stato_nascita: user.stato_nascita,
          prov_nascita: user.prov_nascita,
          citta_nascita: user.citta_nascita,
          sesso: user.sesso,
          codice_sdi: user.codice_sdi,
        }))
        // console.log(this.collaborators)
      }
    })
      .catch(e => e)
  },
  methods: {
    pushProfile(data) {
      this.permissions.push({ title: data.ruolo, id: data.id })
    },
  },
}
</script>
<style scoped>
  .cardPointer{
    position: relative;
    top: 0;
    transition: top ease 0.5s;
  }
  .cardPointer:hover{
    cursor: pointer;
    top: -10px;

  }
</style>
